import { required, email, helpers, minValue, maxValue, minLength, maxLength } from '@vuelidate/validators';

const maxFileSize = sizeMB =>
    helpers.withParams({ type: 'maxFileSize', value: sizeMB }, value => {
        if (!value) return true;

        const fileSizeinMB = value.size / 1024 / 1024;
        return fileSizeinMB <= sizeMB;
    });

const allowedExtensions = extensions =>
    helpers.withParams({ type: 'allowedExtensions', value: extensions }, value => {
        if (!value) return true;

        return extensions.includes(value.type);
    });

const regex = pattern => helpers.withMessage('Invalid format.', helpers.regex(new RegExp(pattern)));

const formUtils = {
    getFieldValidations(field) {
        try {
            const available_rules = ['required', 'email', 'min', 'max', 'pattern', 'size', 'ext'];
            const field_rules = {};
            const validations = {};

            available_rules.forEach(rule => {
                const fieldRule = field[rule] || field?.rules[rule];
                if (fieldRule) {
                    field_rules[rule] = fieldRule;   
                }
            });

            const rules = {
                required: { rule: required, condition: field_rules.required },
                email: { rule: email, condition: field.type === 'email' },
                pattern: { rule: regex(field_rules.pattern), condition: field_rules.pattern },
                minValue: { rule: minValue(field_rules.min), condition: field.type === 'number' && field_rules.min },
                maxValue: { rule: maxValue(field_rules.max), condition: field.type === 'number' && field_rules.max },
                minLength: { rule: minLength(field_rules.min), condition: field.type !== 'number' && field_rules.min },
                maxLength: { rule: maxLength(field_rules.max), condition: field.type !== 'number' && field_rules.max },
                size: { rule: maxFileSize(field_rules.size), condition: field.type === 'file' && field_rules.size },
                ext: { rule: allowedExtensions(field_rules.ext), condition: field.type === 'file' && field_rules.ext },
            };

            for (const [key, value] of Object.entries(rules)) {
                if (value.condition) {
                    validations[key] = value.rule;
                }
            }

            return validations;
        } catch (error) {
            return {};
        }
    },
};

export default formUtils;
