<template>
    <div class="billing-information-form">
        <ui-card class="mb-5" title="Billing details" description="Below you can enter the account billing information.">
            <ui-billing-information-form-skeleton v-if="loading" />
            <template v-else>
                <ui-list>
                    <template v-for="section in formConfig" :key="section.title">
                        <ui-list-item v-for="field in section.fields" :key="field.name">
                            <div class="row w-100">
                                <div class="col-md-5 d-flex align-items-start justify-content-center flex-column pe-3">
                                    <h6 class="billing-information-form__item-title">{{ field.label }}</h6>
                                    <p v-if="field.description" class="billing-information-form__item-description">
                                        {{ field.description }}
                                    </p>
                                </div>
                                <div class="col-md-7">
                                    <ui-generic-input
                                        v-model:value="formData[field.name]"
                                        :id="field.id"
                                        :name="field.name"
                                        :type="field.type"
                                        :label="field.label"
                                        :source="field.source"
                                        :data="field.data"
                                        :option-text="field.option_text"
                                        :option-value="field.option_value"
                                        :disabled="field.disabled"
                                        :debounce="field.debounce"
                                        :field="v$.formData[field.name]"
                                        :on-change="field.on_change"
                                    />
                                </div>
                            </div>
                        </ui-list-item>
                    </template>
                </ui-list>
                <div class="text-end" v-if="CAN(['write_accounts'])">
                    <ui-button type="submit" variant="primary" :loading="saving" @click="saveBillingInformation">
                        Save changes
                    </ui-button>
                </div>
            </template>
        </ui-card>
    </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core'

import UiBillingInformationFormSkeleton from '@/components/ui/skeleton/BillingInformationFormSkeleton.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card';
import UiGenericInput from '@/components/ui/inputs/GenericInput';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

import formUtils from '@/utils/forms.utils';
import formMixin from '@/mixins/form.mixin';

export default {
    name: 'BillingInformationForm',
    components: {
        UiBillingInformationFormSkeleton,
        UiButton,
        UiCard,
        UiGenericInput,
        UiList,
        UiListItem,
    },
    mixins: [formMixin],
    setup: () => {
        const externalResults = ref({});
        return {
            externalResults,
            v$: useVuelidate({ $externalResults: externalResults, $autoDirty: true }),
        };
    },
    props: {
        accountId: {
            type: String,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            formConfig: [],
            isCreating: false,
            loading: false,
            saving: false,
        };
    },
    computed: {
        ...mapState('billingInformation', {
            values: state => state.values,
        }),
        rules() {
            const validations = { formData: {} };

            this.formConfig.forEach(section => {
                section.fields.forEach((field) => {
                    const field_config = { ...field };

                    validations.formData[field.name] = formUtils.getFieldValidations(field_config);
                })
            });

            return validations;
        },
        formData: {
            get: function () {
                return this.values;
            },
            set: function (newValue) {
                this.updateValues(newValue);
            },
        },
    },
    validations() {
        return this.rules;
    },
    mounted() {
        this.getForm();
    },
    methods: {
        ...mapActions({ updateValues: 'billingInformation/updateValues' }),
        async getForm() {
            try {
                this.loading = true;

                const config = { params: { country: 'MX', name: 'billings-form' } };
                const { data } = await this.$axios.get('/_/forms', config);

                this.formConfig = data[0].form;

                await this.getBillingInformation();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async getBillingInformation() {
            try {
                const { data } = await this.$axios.get(`/_/accounts/${this.accountId}/billing-information`);
                this.formData = data;
            } catch (error) {
                const status = error?.response?.status;

                if (status === 404) {
                    this.isCreating = true;
                    this.formData.country = 'MEX';
                    return;
                }

                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async saveBillingInformation() {
            try {
                this.saving = true;

                const is_valid = await this.v$.$validate();

                if (!is_valid) {
                    this.scrollToError();
                    return;
                }

                await this.$axios({
                    method: this.isCreating ? 'post' : 'put',
                    url: `/_/accounts/${this.accountId}/billing-information`,
                    data: this.formData
                })

                if (this.isCreating) {
                    this.isCreating = false;
                }

                this.$toast.success('Billing information updated successfully');
            } catch (error) {
                const status = error?.response?.status;
                const details = error?.response?.data?.details;

                if (status === 400 && details?.length) {
                    const formattedErrors = {};

                    details.forEach(error => {
                        formattedErrors[error.field] = [error.message];
                    });

                    Object.assign(this.externalResults, {
                        formData: formattedErrors,
                    });

                    this.scrollToError();
                    return;
                }

                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>

<style lang="scss">
.billing-information-form {
    &__item-title {
        color: $ecart-secondary;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__item-description {
        color: $ecart-secondary-400;
        font-size: 0.93rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 5px;
    }
}
</style>
