<template>
    <ui-card>
        <div class="account-rating-card">
            <h5 class="account-rating-card__title">Commercial rating</h5>
            <p class="account-rating-card__description">How does the commercial department rate this lead?</p>
            <div class="account-rating-card__stars-wrapper">
                <button
                    v-for="rating in ratings"
                    :key="rating.value"
                    v-b-tooltip="rating.label"
                    class="account-rating-card__button"
                    type="button"
                    :class="{
                        'account-rating-card__button--active': currentRating >= rating.value || hoverRating >= rating.value,
                    }"
                    :disabled="saving || !CAN(['write_ratings'])"
                    @mouseover="handleHover(rating.value)"
                    @mouseleave="hoverRating = 0"
                    @click="saveRating(rating.value)"
                >
                    <span
                        class="account-rating-card__star fa-star"
                        :class="{
                            fas: currentRating >= rating.value || hoverRating >= rating.value,
                            far: currentRating < rating.value || hoverRating < rating.value,
                        }"
                    ></span>
                </button>
            </div>
            <small class="account-rating-card__label">
                <template v-if="currentRating">
                    ({{ ratings[currentRating - 1].label }})
                </template>
                <template v-else>
                    (Not yet rated)
                </template>
            </small>
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';

import scopes_mixin from '@/mixins/scopes.mixin'

export default {
    name: 'AccountRatingCard',
    components: { UiCard },
    props: {
        account: {
            default: null,
            required: true,
            type: Object,
        },
    },
    mixins: [scopes_mixin],
    data() {
        return {
            hoverRating: 0,
            currentRating: 0,
            ratings: [
                { value: 1, label: 'Very bad' },
                { value: 2, label: 'Bad' },
                { value: 3, label: 'Average' },
                { value: 4, label: 'Good' },
                { value: 5, label: 'Excellent' },
            ],
            saving: false,
        };
    },
    mounted() {
        if (this.account?.rating) {
            this.currentRating = this.account.rating;
        }
    },
    methods: {
        handleHover(rating) {
            if (this.CAN(['write_ratings'])) {
                this.hoverRating = rating;
            }
        },
        async saveRating(rating) {
            const current_rating = this.currentRating;

            try {
                this.saving = true;
                this.currentRating = rating;

                await this.$axios.put(`/_/accounts/${this.account.id}/rating`, { rating });
                this.$emit('rating-updated', rating);
            } catch(error) {
                this.currentRating = current_rating;
                this.showError(error);
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.account-rating-card {
    align-items: center;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    display: flex;
    justify-content: center;

    &__title {
        font-weight: 600;
        margin-bottom: 0;
    }

    &__description {
        color: $ecart-secondary-500;
        font-weight: 400;
        margin-bottom: 0;
    }

    &__stars-wrapper {
        background-color: #F4F3F3;
        border-radius: 16px;
        display: flex;
        padding: 4px 8px;
    }

    &__button {
        background: none;
        border: none;
        color: #9d9d9d;
        font-size: 1.6rem;
        padding: 4px 8px;
        transition: 0.15s ease-in-out;
        
        &:hover:not([disabled]) {
            color: #FFC107;
        }

        &:hover:disabled {
            cursor: not-allowed;
        }

        &--active {
            color: #FFC107;
        }
    }

    &__label {
        color: #8D8D8D;
        font-weight: 500;
        font-size: 1rem;
    }
}
</style>
