import { required } from '@vuelidate/validators'

export default {
    props: {
        disabledInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        hiddenInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
    },

    data() {
        let formData = {
            name: '',
            address1: '',
            address2: '',
            district: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            phone: ''
        };

        formData = Object.keys(formData)
            .filter(key => !this.hiddenInputs.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: formData[key] }), {});

        return { formData };
    },

    validations () {
        let validations = {
            name: { required, $autoDirty: true },
            address1: { required, $autoDirty: true },
            address2: { required, $autoDirty: true },
            district: { required, $autoDirty: true },
            city: { required, $autoDirty: true },
            state: { required, $autoDirty: true },
            country: { required, $autoDirty: true },
            postalCode: { required, $autoDirty: true },
            phone: { required, $autoDirty: true }
        }

        validations = Object.keys(validations)
            .filter(key => !this.hiddenInputs.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: validations[key] }), {});

        return { formData: validations };
    },

    methods: {
        async validate() {
           return await this.v$.$validate();
        },
        reset() {
            for (const key in this.formData) {
                this.formData[key] = key === 'active' ? false : '';
            }
            this.v$.formData.$reset();
        },
    },
};