<template>
    <div class="invoice-list">
        <ui-list-group-skeleton v-if="loading" :rows="5" />
        <list-group v-else bordered shadow>
            <list-group-item class="py-3">
                <h5 class="ui-card__title mb-0 my-1">Invoice list</h5>
            </list-group-item>
            <list-group-item v-for="invoice in invoices" :key="invoice.id">
                <invoice-item :invoice="invoice" @invoiceUpdated="invoiceUpdated" />
            </list-group-item>
            <list-group-item v-if="!invoices.length">
                <empty-state title="No invoices found..."/>
            </list-group-item>
            <list-group-item v-if="total_pages > 1" class="py-3">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="my-1" />
            </list-group-item>
        </list-group>
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';
import InvoiceItem from '@/components/accounts/InvoiceItem';
import ListGroup from '@/components/ui/list-group/ListGroup';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem';
import UiListGroupSkeleton from '@/components/ui/skeleton/ListGroupSkeleton';
import UiPagination from '@/components/ui/Pagination';

export default {
    name: 'InvoiceList',
    components: {
        EmptyState,
        InvoiceItem,
        ListGroup,
        ListGroupItem,
        UiListGroupSkeleton,
        UiPagination,
    },
    props: {
        accountId: {
            type: String,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            filters: {
                page: 1,
                limit: 50,
            },
            invoices: [],
            loading: false,
            saving: false,
            total_pages: null,
        };
    },
    mounted() {
        this.getInvoices();
    },
    methods: {
        invoiceUpdated(invoice) {
            const searchIndex = this.invoices.findIndex(item => item.id === invoice.id);
            this.invoices.splice(searchIndex, 1, invoice);
        },
        async getInvoices() {
            try {
                this.loading = true;
                
                const config = { params: { account_id: this.accountId } };
                const { data } = await this.$axios.get('/_/invoices', config);

                this.invoices = data.docs;
                this.total_pages = data.pages;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
