<template>
    <ui-button
        :variant="variant"
        :icon="icon"
        :loading="isLoading"
        @click="handleDownload()"
    >
        {{ this.text }}
    </ui-button>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';

export default {
    name: 'ButtonDownload',
    components: {
        UiButton
    },
    props: {
        link: {
            type: String,
            default: null,
            required: true,
        },
        fileExtension: {
            type: String,
            default: 'csv',
            required: false,
        },
        mimeType: {
            type: String,
            default: 'text/csv',
            required: false,
        },
        variant: {
            type: String,
            default: 'light',
            required: false,
        },
        icon: {
            type: String,
            default: 'fas fa-download',
            required: false,
        }, 
        text: {
            type: String,
            default: 'Download',
            required: false,
        }
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        async handleDownload() {
            try {
                this.isLoading = true;
                const response = await this.$axios.get(this.link);
                this.downloadFile(response);
            } catch (error) {
                this.handleError(error);
            } finally {
                this.isLoading = false;
            }
        },
        downloadFile(response) {
            const el = document.createElement('a');
            el.href = window.URL.createObjectURL(new Blob([response.data], { type: this.mimeType }));
            let match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers['content-disposition']);
            el.download = match?.[1] ? match[1].replace(/['"]/g, '') : `file.${this.fileExtension}`;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            window.URL.revokeObjectURL(el);
        },
        handleError(error) {
            this.$toast.error(this.getErrorMessage(error));
        }
  },
};
</script>
