<template>
    <div class="invoice-item">
        <div class="invoice-item__content">
            <div class="d-flex align-items-center">
                <button
                    class="invoice-item__show-details-button"
                    :class="{ 'invoice-item__show-details-button--active': showDetails }"
                    variant="link"
                    @click="handleShowDetails"
                >
                    <span class="fas fa-chevron-right"></span>
                </button>
                <div>
                    <p class="mb-1">
                        {{ months[invoice.month - 1] }}, {{ invoice.year }}
                    </p>
                    <ui-status class="fs-13px m-0" :status="invoice.status || 'no_information'" />
                </div>
            </div>
            <div class="me-sm-5">
                {{ _currency(invoice.amount, invoice.currency, true) }}
            </div>
        </div>
        <div class="invoice-item__actions">
            <div v-if="CAN(['write_invoices'])">
                <ui-button-group>
                    <ui-button
                    variant="outline-primary"
                    icon="fas fa-download"
                    :disabled="invoice.status === 'pending'"
                    :loading="downloadingInvoice"
                    @click="downloadInvoice(invoice.id)"
                    >
                    Download
                    </ui-button>
                    <ui-dropdown ref="dropdown" variant="outline-primary">
                        <template #button-content>
                            <i class="fas fa-ellipsis-v"></i>
                        </template>
                    <ui-dropdown-item v-if="CAN(['read_transactions', 'write_transactions'])" :to="{ name: 'transactions', query: { invoice_id: invoice.id } }" target="_blank">
                        See details
                    </ui-dropdown-item>
                    <ui-dropdown-item v-if="invoice.status === 'pending' && CAN(['write_invoices'])" :disabled="sendingToBill" @click.stop="sendToBill(invoice.id)">
                        <span v-if="sendingToBill" class="fas fa-spinner fa-spin"></span>
                        Send to bill
                    </ui-dropdown-item>
                </ui-dropdown>
            </ui-button-group>
            </div>
            <div v-else>
                <ui-button variant="outline-primary" @click="handleShowDetails()">
                    <span class="fas fa-angle-down"></span>
                    <span class="ms-2"> See Details</span>
                </ui-button>
            </div>
        </div>
    </div>
    <div v-if="showDetails" class="invoice-item__details">
        <div class="row">
            <div class="col-md-6 mb-4 mb-md-0">
                <h5 class="invoice-item__details-title">
                    Details
                </h5>
                <ui-list bordered>
                    <ui-list-item class="px-3" title="Invoice ID:">
                        <template #text>
                            <ui-badge v-if="!invoice.provider_invoice_id" variant="secondary">
                                No information
                            </ui-badge>
                            {{ invoice.provider_invoice_id }}
                        </template>
                    </ui-list-item>
                    <ui-list-item class="px-3" title="Year:" :text="invoice.year" />
                    <ui-list-item class="px-3" title="Month:">
                        <template #text>
                            {{ months[invoice.month - 1] }}
                        </template>
                    </ui-list-item>
                    <ui-list-item class="px-3" title="Amount:">
                        <template #text>
                            {{ _currency(invoice.amount, invoice.currency, true) }}
                        </template>
                    </ui-list-item>
                    <ui-list-item class="px-3" title="Billing at:">
                        <template #text>
                            <template v-if="invoice.billing_at">
                                {{ $dayjs(invoice.billing_at).format('LL, LT') }}
                            </template>
                            <ui-badge v-else variant="secondary">
                                No information
                            </ui-badge>
                        </template>
                    </ui-list-item>
                    <ui-list-item class="px-3" title="Created at:">
                        <template #text>
                            {{ $dayjs(invoice.created_at).format('LL, LT') }}
                        </template>
                    </ui-list-item>
                    <ui-list-item class="px-3" title="Updated at:">
                        <template #text>
                            {{ $dayjs(invoice.update_at).format('LL, LT') }}
                        </template>
                    </ui-list-item>
                </ui-list>
            </div>
            <div class="col-md-6">
                <h5 class="invoice-item__details-title">
                    Concepts
                </h5>
                <ui-table class="mb-4" :shadow="false" bordered :items="invoice.concepts" :fields="fields">
                    <template #cell(amount)="data">
                        {{ _currency(data.item.exchange.fee, data.item.exchange.currency, true) }}
                    </template>
                </ui-table>
            </div>
        </div>
    </div>
</template>

<script>
import UiBadge from '@/components/ui/Badge';
import UiButton from '@/components/ui/buttons/Button';
import UiButtonGroup from '@/components/ui/buttons/ButtonGroup';
import UiDropdown from '@/components/ui/dropdown/Dropdown';
import UiDropdownItem from '@/components/ui/dropdown/DropdownItem';
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table';

export default {
    name: 'InvoiceItem',
    components: {
        UiBadge,
        UiButton,
        UiButtonGroup,
        UiDropdown,
        UiDropdownItem,
        UiList,
        UiListItem,
        UiStatus,
        UiTable,
    },
    props: {
        invoice: {
            type: Object,
            default: null,
            required: true,
        },
    },
    emits: {
        invoiceUpdated: null,
    },
    data() {
        return {
            downloadingInvoice: false,
            fields: [
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
            ],
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            sendingToBill: false,
            showDetails: null,
        };
    },
    methods: {
        handleShowDetails() {
            this.showDetails = !this.showDetails;
        },
        async downloadInvoice(id) {
            try {
                this.downloadingInvoice = true;

                const options = { responseType: 'blob' };
                const response = await this.$axios.get(`/_/invoices/cfdi/download/${id}`, options);
                const file = window.URL.createObjectURL(new Blob([response.data]));
                const file_url = document.createElement('a');
    
                file_url.href = file;
                file_url.setAttribute('download', `invoice-${id}.zip`);
                document.body.appendChild(file_url);
                file_url.click();
                document.body.removeChild(file_url);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.downloadingInvoice = false;
            }
        },
        async sendToBill(id) {
            try {
                this.sendingToBill = true;
                const { data } = await this.$axios.post(`/_/invoices/${id}/bill`);
                
                this.$refs.dropdown.$el.click();
                this.$emit('invoiceUpdated', data);
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingToBill = false;
            }
        }
    },
};
</script>

<style lang="scss">
.invoice-item {
    display: flex;
    gap: 8px;
    flex-direction: column;
    
    @media (min-width: 576px) {
        align-items: center;
        flex-direction: initial;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        
        @media (min-width: 576px) {
            align-items: center;
            flex-direction: initial;
            justify-content: space-between;
        }
    }

    &__show-details-button {
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 100%;
        color: $ecart-secondary-600;
        display: flex;
        font-size: 1rem;
        justify-content: center;
        height: 35px;
        line-height: 1;
        transition: 200ms all;
        width: 35px;

        &--active {
            transform: rotate(90deg);
        }
    }

    &__actions {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__title {
        color: $ecart-secondary;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__description {
        color: $ecart-secondary-400;
        font-size: 0.93rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 5px;
    }

    &__details {
        padding-top: 24px;
    }

    &__details-title {
        font-weight: 600;
        font-size: 1.05rem;
    }
}
</style>
