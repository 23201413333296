<template>
    <div class="account-info">
        <b-button class="mb-3" variant="light" @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <template v-if="userInfo">
            <h5 class="account-info__name">
                {{ userInfo.first_name }} {{ userInfo.last_name || '' }}
            </h5>
            <p class="account-info__account-number">
                #{{ userInfo.id }}
            </p>
        </template>
        <ui-tabs>
            <ui-tab title="Account details">
                <div class="row">
                    <div class="col-md-3 mb-3">
                        <ui-stat-card-skeleton v-if="loadingUserInfo" />
                        <ui-stat-card
                            v-else
                            class="h-100 mb-0"
                            title="Risk score"
                            number="N/A"
                            icon="fas fa-exclamation-triangle"
                            icon-variant="danger"
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <ui-stat-card-skeleton v-if="loadingUserInfo" />
                        <ui-stat-card
                            v-else
                            class="h-100 mb-0"
                            title="Chargeback Balance"
                            :number="getChargebackAmount()"
                            icon="fas fa-hand-holding-usd"
                            icon-variant="info"
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <ui-stat-card-skeleton v-if="loadingUserInfo" />
                        <ui-stat-card
                            v-else
                            class="h-100 mb-0"
                            title="Percentage retained"
                            number="N/A"
                            icon="fas fa-wallet"
                            icon-variant="warning"
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <ui-stat-card-skeleton v-if="loadingUserInfo" />
                        <ui-stat-card
                            v-else
                            class="h-100 mb-0"
                            title="Current Balance"
                            :number="getBalanceAmount()"
                            icon="fas fa-money-bill"
                            icon-variant="success"
                        />
                    </div>
                    <template v-if="accountExists">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12" v-if="userInfo && userInfo.verified == true">
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                    <ui-card v-else title="American express service">
                                        <activate-gateway-amex :info="userInfo" />
                                    </ui-card>
                                </div>
                                <div class="col-md-12">
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                    <ui-card v-else title="User information">
                                        <user-info :info="userInfo" />
                                    </ui-card>
                                </div>
                                <div class="col-md-12">
                                    <ui-card
                                        title="Account comments"
                                        description="To register a comment it is necessary to complete the following information."
                                    >
                                        <comments :account-id="accountId" :scope="'write_accounts'" />
                                    </ui-card>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                    <rating-card v-else :account="userInfo" @ratingUpdated="ratingUpdated" />
                                </div>
                                <div class="col-md-12">
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                    <ui-card v-else title="Balance details" help-text="It is the money that you can use to pay or withdraw from a bank account">
                                        <card-money :show-total="false" :summary="summary_balances" />
                                    </ui-card>
                                </div>
                                <div class="col-md-12">
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                    <ui-card v-else title="Chargeback balance details" help-text="It is the money that is received as a chargeback for an unrecognized purchase">
                                        <card-money :show-total="false" :summary="summary_chargeback" />
                                    </ui-card>
                                </div>
                                <div class="col-md-12">
                                    <withheld-amount />
                                </div>
                                <div>
                                    <ui-card-skeleton v-if="loadingUserInfo" />
                                        <div v-else>
                                            <ui-card 
                                                title="Events" 
                                                title-icon="fas fa-history"
                                        >
                                            <time-line :event-data="timeLine || time_line" />
                                            </ui-card>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="col-md-12">
                        <ui-card>
                            <empty-state img="disconnected.svg" title="The account don't have credentials." />
                        </ui-card>
                    </div>
                </div>
            </ui-tab>
            <ui-tab v-if="showBillingTab" title="Billing information">
                <div class="row">
                    <div class="col-md-12">
                        <billing-information-form :account-id="accountId" />
                    </div>
                    <div class="col-md-12" v-if="CAN(['write_accounts', 'read_accounts'])">
                        <invoice-list :account-id="accountId" />
                    </div>
                </div>
            </ui-tab>
            <ui-tab title="Transactions">
                <account-transactions />
            </ui-tab>
            <ui-tab title="Activity">
                <account-activitys />
            </ui-tab>
            <ui-tab title="Gateways">
                <gateway-list />
            </ui-tab>
            <ui-tab title="Pricings">
                <pricing-list />
            </ui-tab>
            <ui-tab title="Limits">
                <limit-account />
            </ui-tab>
            <ui-tab title="Address">
                <address-list />
            </ui-tab>
        </ui-tabs>
    </div>
</template>

<script>
import AccountTransactions from '@/components/accounts/AccountTransactions';
import AccountActivitys from '@/components/accounts/AccountActivitys';
import ActivateGatewayAmex from '@/components/accounts/ActivateGatewayAmex';
import AddressList from '@/components/accounts/AddressList';
import BButton from '@/components/bootstrap/components/Button/Button';
import BillingInformationForm from '@/components/accounts/BillingInformationForm'
import CardMoney from '@/components/balance/CardMoney';
import Comments from '@/components/accounts/Comments';
import EmptyState from '@/components/ui/EmptyState';
import GatewayList from '@/components/accounts/GatewayList';
import InvoiceList from '@/components/accounts/InvoiceList';
import LimitAccount from '@/components/accounts/LimitAccount.vue';
import PricingList from '@/components/accounts/PricingList';
import RatingCard from '@/components/accounts/RatingCard.vue';
import TimeLine from '@/components/activations/TimeLine';
import UiCard from '@/components/ui/Card';
import UiCardSkeleton from '@/components/ui/skeleton/CardSkeleton';
import UiStatCard from '@/components/ui/StatCard';
import UiStatCardSkeleton from '@/components/ui/skeleton/StatCardSkeleton';
import UiTabs from '@/components/ui/tabs/Tabs';
import UiTab from '@/components/ui/tabs/Tab';
import UserInfo from '@/components/accounts/UserInfo';
import WithheldAmount from '@/components/accounts/WithheldAmount';

export default {
    components: {
        AccountTransactions,
        AccountActivitys,
        ActivateGatewayAmex,
        AddressList,
        BButton,
        BillingInformationForm,
        CardMoney,
        Comments,
        EmptyState,
        GatewayList,
        InvoiceList,
        LimitAccount,
        PricingList,
        RatingCard,
        TimeLine,
        UiCard,
        UiCardSkeleton,
        UiTabs,
        UiStatCard,
        UiStatCardSkeleton,
        UiTab,
        UserInfo,
        WithheldAmount,
    },
    data() {
        return {
            accountExists: true,
            accountId: this.$route.query.id,
            loadingUserInfo: false,
            summary_balances: null,
            summary_chargeback: null,
            userInfo: null,
            time_line: [],
        };
    },
    computed: {
        showBillingTab() {
            return this.userInfo?.country === 'MX';
        },
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        ratingUpdated(rating) {
            this.userInfo.rating = rating;
        },
        getAmount(summary) {
            const total = summary?.total;
            const currency = summary?.currency;
            if (total) {
                return this._currency(total, currency)
            }
            return this._currency(0, currency);
        },
        getBalanceAmount() {
            return this.getAmount(this.summary_balances);
        },
        getChargebackAmount() {
            return this.getAmount(this.summary_chargeback);
        },
        async getSummary() {
            try {
                const { data } = await this.$axios.get(`/_/accounts/${this.accountId}/summary`);
                this.summary_balances = data;
                this.summary_chargeback = data.chargebacks;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async getEvents() {
            try {
                const { data } = await this.$axios.get(`/_/accounts/${this.accountId}/events`);
                this.time_line = data;
                this.timeLine = data;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async getUserInfo() {
            try {
                this.loadingUserInfo = true;

                const { data } = await this.$axios.get(`/_/accounts/${this.accountId}`);

                this.userInfo = data;

                if (this.userInfo) {
                    await this.getSummary();
                    await this.getEvents();
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
                this.accountExists = false;
            } finally {
                this.loadingUserInfo = false;
            }
        },
    },
};
</script>
