<template>
    <div class="limit-table">
        <ui-pricing-list-skeleton v-if="loading" :rows="1" />
        <template v-else>
            <ui-card>
                <div class="limit-table__row">
                    <h5 class="limit-table__second_tittle">Withdrawal limit</h5>
                    <div class="limit-table__box">
                        <ui-button v-if="!withdrawals_edit" variant="outline-primary" icon="fas fa-pen" @click="withdrawalsEditLimit('edit')">
                            Edit
                        </ui-button>
                        <ui-button v-else variant="outline-primary" icon="fas fa-check" @click="withdrawalsEditLimit('save')"> Save </ui-button>
                        <div class="limit-table__box">
                            <div class="limit-table__details">
                                <div class="limit-table__details-content">
                                    <p class="limit-table__title">Is percent:</p>
                                </div>
                            </div>
                            <div class="limit-table__actions">
                                <input :id="`withdrawals`" :name="`withdrawals`" v-model="percent" @change="!percent" type="checkbox" />
                            </div>
                        </div>
                    </div>
                    <div v-if="limits_withdrawals.length">
                        <div class="limit-table__limit" v-for="limit in limits_withdrawals" :key="limit.id">
                            <div class="limit-table__limit" v-for="amounts in limit.amounts" :key="amounts.currency">
                                <limit-item
                                    v-if="limit.type === 'withdrawals'"
                                    :amounts="amounts"
                                    type="withdrawals"
                                    :percent="limit.is_percent"
                                    :options="options"
                                    :currencys="currencys"
                                    :disabledLimit="!withdrawals_edit"
                                    @update:limit="setEdit"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else class="limit-table__limit">
                        <limit-item
                            :amounts="defaultLimit"
                            type="withdrawals"
                            :percent="percent"
                            :options="options"
                            :currencys="currencys"
                            :disabledLimit="!withdrawals_edit"
                            @update:limit="setEdit"
                        />
                    </div>
                </div>
                <div class="limit-table__row">
                    <h5 class="limit-table__second_tittle">Checkout limit</h5>
                    <div class="limit-table__box">
                        <ui-button variant="outline-primary" icon="fas fa-plus-circle" @click="addCheckoutLimit"> Add </ui-button>
                        <ui-button v-if="!checkout_edit" variant="outline-primary" icon="fas fa-pen" @click="checkoutEditLimit('edit')">
                            Edit
                        </ui-button>
                        <ui-button v-else variant="outline-primary" icon="fas fa-check" @click="checkoutEditLimit('save')"> Save </ui-button>
                    </div>
                    <div v-if="limits_checkout.length">
                        <div class="limit-table__limit" v-for="limit in limits_checkout" :key="limit.id">
                            <div class="limit-table__limit" v-for="amounts in limit.amounts" :key="amounts.currency">
                                <limit-item
                                    v-if="limit.type === 'checkout'"
                                    :amounts="amounts"
                                    type="checkout"
                                    :percent="limit.is_percent"
                                    :options="options"
                                    :currencys="currencys"
                                    :disabledLimit="!checkout_edit"
                                    :account_id = "limit?.account_id"
                                    @update:limit="setEdit"
                                    @delete:limit="setDelete"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else class="limit-table__limit">
                        <limit-item
                            :amounts="defaultLimit"
                            type="checkout"
                            :percent="false"
                            :options="options"
                            :currencys="currencys"
                            :disabledLimit="!checkout_edit"
                            @update:limit="setEdit"
                            @delete:limit="setDelete"
                        />
                    </div>
                </div>
            </ui-card>
        </template>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card';
import UiPricingListSkeleton from '@/components/ui/skeleton/PricingListSkeleton';
import LimitItem from './LimitItem.vue';
import filters from '@/data/filters';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'LimitAccount',
    components: {
        UiCard,
        UiPricingListSkeleton,
        LimitItem,
        UiButton,
    },
    data() {
        return {
            loading: false,
            limits_withdrawals: [
                {
                    type: 'withdrawals',
                    is_percent: false,
                    amounts: [
                        {
                            max: 0,
                            currency: 'MXN',
                        },
                    ],
                },
            ],
            limits_checkout: [
                {
                    type: 'checkout',
                    is_percent: false,
                    amounts: [
                        {
                            min: 0,
                            max: 0,
                            currency: 'MXN',
                        },
                    ],
                },
            ],
            defaultLimit: {
                min: 0,
                max: 0,
                currency: 'MXN',
            },
            percent: false,
            options: [
                { title: 'Percent', value: true },
                { title: 'Amount', value: false },
            ],
            riskOptions: [
                { title: 'Low', value: '100' },
                { title: 'Medium', value: '50' },
                { title: 'High', value: '0' },
            ],
            currencys: filters.withdrawals.currency,
            withdrawals_edit: false,
            checkout_edit: false,
            change_whitdrawals_value: [],
            change_checkout_value: [],
            selectRisk: null,
        };
    },
    mounted() {
        this.getLimit();
    },
    watch: {
        limits_checkout: {
            handler() {
                let currencyList = this.limits_checkout[0].amounts.map((currency) => currency.currency);
                this.currencys = this.currencys.filter((currency) => !currencyList.includes(currency.value));
            },
            deep: true,
        },
    },
    methods: {
        setEdit(data, type) {
            if (type === 'withdrawals') {
                const limitIndex = this.change_whitdrawals_value.findIndex((limit) => limit.currency === data.currency);
                if (limitIndex !== -1) {
                    this.change_whitdrawals_value[limitIndex] = data;
                } else {
                    this.change_whitdrawals_value.push(data);
                }
            } else if (type === 'checkout') {
                const limitIndex = this.change_checkout_value.findIndex((limit) => limit.currency === data.currency);
                if (limitIndex !== -1) {
                    this.change_checkout_value[limitIndex] = data;
                } else {
                    this.change_checkout_value.push(data);
                }
            } else {
                this.percent = true;
                this.withdrawals_edit = true;
                this.change_whitdrawals_value = [];
                for (let limit of this.limits_withdrawals[0].amounts) {
                    limit.max = data;
                    this.change_whitdrawals_value.push(limit);
                }
                this.withdrawalsEditLimit('save');
            }
        },
        async getLimit() {
            try {
                this.loading = true;
                const params = {
                    account_id: this.$route.query.id,
                };
                const { data } = await this.$axios.get(`/_/limits`, { params });
                for (const limit of data.docs) {
                    if (limit.type === 'withdrawals') {
                        this.limits_withdrawals = [limit];
                        this.percent = limit.is_percent;
                    } else {
                        this.limits_checkout = [limit];
                    }
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async withdrawalsEditLimit(action) {
            try {
                this.withdrawals_edit = !this.withdrawals_edit;
                
                let obj = {
                    amounts: this.change_whitdrawals_value,
                    is_percent: this.percent,
                };

                if (this.percent) {
                    for (let limit of this.change_whitdrawals_value) {
                        if ( limit.max > 100) {
                            this.$toast.error(`The currency ${limit.currency} limit must be less than 100%`);
                            return;
                        }
                    }
                }
                if (action === 'save') {
                    if (this.limits_withdrawals.length > 0 && this.limits_withdrawals[0].account_id) {
                        const { data } = await this.$axios.put(`/_/limits/${this.limits_withdrawals[0].id}`, obj);
                        this.limits_withdrawals = [data];
                    } else {
                        obj.account_id = this.$route.query.id;
                        obj.type = 'withdrawals';
                        const { data } = await this.$axios.post(`/_/limits`, obj);
                        this.limits_withdrawals = [data];
                    }
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async checkoutEditLimit(action) {
            try {
                this.checkout_edit = !this.checkout_edit;
                let obj = {
                    amounts: this.change_checkout_value,
                    is_percent: false,
                };
                if (action === 'save') {
                    if (this.limits_checkout.length > 0 && this.limits_checkout[0].account_id) {
                        const { data } = await this.$axios.put(`/_/limits/${this.limits_checkout[0].id}`, obj);
                        this.limits_checkout = [data];
                    } else {
                        obj.account_id = this.$route.query.id;
                        obj.type = 'checkout';
                        const { data } = await this.$axios.post(`/_/limits`, obj);
                        this.limits_checkout = [data];
                    }
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        addCheckoutLimit() {
            const newLimit = {
                type: 'checkout',
                is_percent: false,
                amounts: [this.defaultLimit],
            };
            if (this.limits_checkout.length != 0) {
                newLimit.id = this.limits_checkout[0].id;
            }
            this.limits_checkout.push(newLimit);
        },
        async setDelete(data) {
            let obj = {
                    amounts: 
                    {
                        currency: data.currency
                    }
            };
            const response = await this.$axios.delete(`/_/limits/${this.limits_checkout[0].id}`, {data: obj});
            this.limits_checkout = [response.data];  
        },
    },
};
</script>

<style lang="scss">
.limit-table {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__details {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            gap: 8px;
        }
    }

    &__details-content {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &__actions {
        align-items: center;
        padding-top: 5px;
        @media (max-width: 768px) {
            gap: 4px;
        }
    }

    &__method-container {
        display: flex;
        gap: 16px;
        width: 100%;
    }

    &__box {
        display: flex;
        gap: 16px;
    }

    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        color: $general-black;
        font-weight: 600;
    }

    &__method-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        margin-right: 10px;
        width: 90px;
    }

    &__input {
        width: 200px;
        padding-left: 10px;
        padding-right: 20px;
        text-align: right;
    }

    &__second_tittle {
        margin-top: 20px;
    }

    &__limit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 10px;
    }

    &__method-text {
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__show-more-button {
        color: $ecart-accent;
        cursor: pointer;
        font-weight: 600;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: center;
    }
}
</style>
