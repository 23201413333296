<template>
    <div class="address-item">
        <div class="address-item__content">
            <div class="d-flex align-items-center">
                <button 
                class="address-item__show-details-button"
                :class="{ 'address-item__show-details-button--active': showDetails }"
                variant="link"
                @click="handleShowDetails(address.name)" >
                    <span class="fas fa-chevron-right"></span>
                </button>
                <div>
                    <h5 class="mb-1">
                        {{ address.name }}
                    </h5>
                    <p>
                        {{ address.address1 }}, {{ address.address2 }} , {{ address.district }} , {{ address.city }} , {{ address.state.code }} , {{ address.country.code }}
                    </p>
                </div>
            </div>
        </div>
        <div class="address-item__actions">
            <div class="col-auto ms-auto">
                <div v-if="CAN(['write_accounts'])">
                    <ui-button-group class="me-2">
                        <ui-button variant="outline-primary" icon="fas fa-angle-down" @click="handleShowDetails(address.name)">
                            <span class="ms-1">See details</span>
                        </ui-button> 
                        <ui-dropdown variant="outline-primary">
                            <template #button-content>
                                <i class="fas fa-ellipsis-v"></i>
                            </template>
                            <ui-dropdown-item @click="this.$refs.updateAddressModal.show()">Update Address</ui-dropdown-item>
                            <ui-dropdown-item @click="this.$refs.deleteAddressModal.show()">Delete Address</ui-dropdown-item>
                        </ui-dropdown>
                    </ui-button-group>
                </div>
                <div v-else>
                    <ui-button variant="outline-primary" @click="handleShowDetails(address.name)">
                        <i class="fas fa-angle-down"></i>
                        <span class="ms-1">See details</span>	
                    </ui-button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showDetails === address.name" class="address-item__details">
        <h5 class="address-item__details-title">
            Details
        </h5>
        <ui-list bordered>
            <ui-list-item class="px-3" title="Name Adress:" :text="address.name" /> 
            <ui-list-item class="px-3" title="Street:" :text="address.address1" /> 
            <ui-list-item class="px-3" title="Number:" :text="address.address2" />
            <ui-list-item class="px-3" title="District:" :text="address.district" />
            <ui-list-item class="px-3" title="City:" :text="address.city" />
            <ui-list-item class="px-3" title="Postal Code:" :text="address.postal_code" />
            <ui-list-item class="px-3" title="State:" :text="address.state.code" />
            <ui-list-item class="px-3" title="Country:">
                <template #text>
                    <img
                    v-if="address.country.code" class="address-item__flag"
                    :src="`https://envia-clients-new.herokuapp.com/flags/${address.country.code.toLowerCase()}.svg`"
                    :alt="address.country.code"
                    />
                </template>
            </ui-list-item>
            <ui-list-item class="px-3" title="Phone:">
                <template #text>
                    <ui-link v-if="address.phone" weight="medium" :link="`tel:+${address.phone}`">
                        {{ address.phone }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
        </ui-list>
    </div>
    <b-modal ref="updateAddressModal" title="Edit Address">
        <div v-if="loading" class="py-5 text-center">
            <custom-spinner class="spinner-border-md" />
        </div>
        <div v-show="!loading">
            <form-address ref="formAddress" />
        </div>
        <template #footer>
            <div v-show="!loading" class="text-end">
                <ui-button :loading="updating" variant="primary" @click="updateAddress" :disabled="!CAN(['write_accounts'])">
                    Update
                </ui-button>
            </div>
            <ui-button variant="secondary" @click="closeModal"> Close </ui-button>
        </template>
	</b-modal>
    <ui-modal ref="deleteAddressModal" centered title="Delete Address" backdrop="static" hide-header-close :keyboard="false">
        <p>
            Are you sure you want to delete the address name '{{ address.name }}'?
            Once deleted it cannot be recovered.
        </p>
        <template #footer>
            <div v-show="!loading" class="text-end">
                <ui-button :loading="deleting" variant="danger" @click="deleteAddress" class="me-2" :disabled="!CAN(['write_accounts'])">
                    Delete
                </ui-button>
                <ui-button variant="primary" @click="this.$refs.deleteAddressModal.hide()">
                    Cancel
                </ui-button>
            </div>
        </template>
    </ui-modal>
</template>

<script>
import UiBadge from '@/components/ui/Badge';
import UiButton from '@/components/ui/buttons/Button';
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table';
import UiLink from '@/components/ui/Link'; 
import CustomSpinner from '@/components/ui/CustomSpinner';
import FormAddress from '@/components/accounts/FormAddress';
import UiModal from '@/components/ui/Modal';
import UiButtonGroup from '@/components/ui/buttons/ButtonGroup';
import UiDropdown from '@/components/ui/dropdown/Dropdown';
import UiDropdownItem from '@/components/ui/dropdown/DropdownItem';
import UiDownloadButton from '@/components/ui/buttons/DownloadButton';

export default {
    name: 'AddressItem',
    components: {
        UiList, 
        UiListItem, 
        UiBadge, 
        UiButton, 
        UiStatus, 
        UiTable,
        UiLink,
        CustomSpinner,
        FormAddress,
        UiModal,
        UiButtonGroup,
        UiDropdown, 
        UiDropdownItem,
        UiDownloadButton  
    },
    emits :{
        addressDeleted: null,
        addressUpdated: null
    },
    data(){
        return {
            accountId: this.$route.query.id,
            showDetails: null,
            loading: false,
            updating: false,
            addressName: this.address.name,
            deleting: false
        }
    },
    props: {
        address: {
            type: Object,
            default: null,
            require: true,
        }
    },
    async mounted(){
        this.getAddress();
    },
    methods: {
        handleShowDetails(name) {
            if (this.showDetails === name) {
                this.showDetails = null;
                return;
            }

            this.showDetails = name;
        },
        closeModal() {
            this.$refs.updateAddressModal.hide();
        },
        async getAddress() {
			try {
				const { data } = await this.$axios.get(`/_/accounts/${this.accountId}/address/${this.addressName}`);
				const formAddress = this.$refs['formAddress'];

				const address = data.addresses[0];

				formAddress.formData = {
					name: address.name,
					address1: address.address1,
					address2: address.address2,
					country: address.country.code,
					state: address.state.code,
					city: address.city,
					postalCode: address.postal_code,
					phone: address.phone,
					district: address.city
				};

				this.addres = address;

			} catch(error) {
				this.$toast.error(this.getErrorMessage(error));
			} finally {
				this.loading = false;
			}
		},
		async updateAddress() {
			const formAddress = this.$refs['formAddress'];

			try {
				this.updating = true;
				const formData = formAddress.formData;
				
                await this.$axios.put(`/_/accounts/${this.accountId}/address/update/${this.addressName}`, formData);
				this.$toast.success('Address information updated successfully.');
                this.$emit('addressUpdated', this.addressName);
                
                this.$refs.updateAddressModal.hide();
			} catch(error) {
				this.$toast.error(this.getErrorMessage(error));
			} finally {
				this.updating = false;
			}
		},
        async deleteAddress() {
            try {
                this.deleting = true;
                await this.$axios.delete(`/_/accounts/${this.accountId}/address/delete/${this.addressName}`);
                this.$toast.success('Address deleted successfully.');
                this.$emit('addressDeleted', this.addressName)

                this.$refs.deleteAddressModal.hide();
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.deleting = false;
            }
            
        },
    }
}
</script>

<style lang="scss">
.address-item {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;

    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }

    &__actions-dropdown {
        color: $ecart-secondary;  
    }

    &__title {
        color: $ecart-secondary;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__description {
        color: $ecart-secondary-400;
        font-size: 0.93rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 5px;
    }

    &__details {
        padding-top: 24px;
    }

    &__details-title {
        font-weight: 600;
        font-size: 1.05rem;
    }

    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__show-details-button {
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 100%;
        color: $ecart-secondary-600;
        display: flex;
        font-size: 1rem;
        justify-content: center;
        height: 35px;
        line-height: 1;
        transition: 200ms all;
        width: 35px;

        &--active {
            transform: rotate(90deg);
        }
    }
}
</style>