<template>
    <div v-if="localAmounts" class="limit-container">
        <h6 class="limit-table__method-title">Details:</h6>
        <ui-input
            v-if = "type === 'checkout'"
            v-model:value="localAmounts.min"
            label="Min."
            :name="localAmounts.currency + 'min'"
            :id="localAmounts.currency + 'min'"
            :disabled="disabledLimit"
            class="limit-table__input"
            type="number"
            @change="adjustLimits"
        />
        <ui-input
            v-model:value="localAmounts.max"
            :name="localAmounts.currency + 'max'"
            :id="localAmounts.currency + 'max'"
            label="Max."
            type="number"
            :disabled="disabledLimit"
            class="limit-table__input"
            @change="adjustLimits"
        />
        <ui-select
            v-if = "type === 'checkout'"
            id="currency"
            class="limit-table__input"
            name="currency"
            label="Currency"
            v-model:value="localAmounts.currency"
            :disabled="disabledLimit"
            :options="listCurrency"
            @change="adjustLimits"
        />
        <ui-input
            v-else
            class="limit-table__input"
            :name="localAmounts.currency + 'currency'"
            :id="localAmounts.currency + 'currency'"
            label="Currency"
            type="select"
            option-label="label"
            option-value="value"
            v-model:value="localAmounts.currency"
            :disabled="true"
        />
        <ui-button v-if = "type === 'checkout' && account_id" variant="outline-primary" icon="fas fa-minus" @click="emitDeleteLimit">
        </ui-button>
    </div>
</template>

<script>
import UiInput from '@/components/ui/Input';
import UiSelect from '@/components/ui/Select';
import UiButton from '@/components/ui/buttons/Button.vue';
export default {
    name: 'LimitItem',
    components: {
        UiInput,
        UiSelect,
        UiButton
    },
    props: {
        amounts: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        percent: {
            type: Boolean,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        currencys: {
            type: Array,
            required: true,
        },
        limitId: {
            type: Number,
            default: null,
            required: false,
        },
        disabledLimit: {
            type: Boolean,
            default: false,
            required: false,
        },
        account_id: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            localAmounts: JSON.parse(JSON.stringify(this.amounts)),
            listCurrency: this.currencys,
        };
    },
    mounted() {
        if (!this.listCurrency.some(item => item.value === this.localAmounts.currency)) {
            this.listCurrency.push({ label: this.localAmounts.currency, value: this.localAmounts.currency });
        }
    },
    methods: {
        adjustLimits() {
            const data = {
                currency: this.localAmounts.currency,
            };
            if (this.type === 'checkout') {
                data.min = this.localAmounts.min;
            }
            data.max = this.localAmounts.max;
            this.$emit('update:limit', data, this.type);
        },
        emitDeleteLimit() {
            const data = {
                currency: this.localAmounts.currency,
            };
        this.$emit('delete:limit', data);
        }
    },
};
</script>

<style lang="scss">
.limit-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
