<template>
    <component
        :id="id"
        :is="currentComponent"
        :label="label"
        :name="name"
        :type="type"
        :options="optionsComputed"
        :option-label="optionText"
        :option-value="optionValue"
        :disabled="disabled"
        :debounce="debounce"
        v-model:value="valueComputed"
    />
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapState } from 'vuex';

import UiAutocompleteInput from '@/components/ui/inputs/AutocompleteInput';
import UiInput from '@/components/ui/Input';
import UiPhoneInput from '@/components/ui/inputs/PhoneInput';
import UiSelect from '@/components/ui/Select';

import utils from '@/utils/main.utils';

export default {
    name: 'GenericInput',
    components: {
        UiAutocompleteInput,
        UiInput,
        UiPhoneInput,
        UiSelect,
    },
    props: {
        data: {
            type: String,
            default: null,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        id: {
            type: String,
            required: true,
            default: `input-${utils.makeId()}`,
        },
        label: {
            type: String,
            required: true,
            default: null,
        },
        name: {
            type: String,
            required: true,
            default: null,
        },
        onChange: {
            type: Array,
            required: false,
            default: null,
        },
        optionText: {
            type: String,
            required: false,
            default: 'name',
        },
        optionValue: {
            type: String,
            required: false,
            default: 'value',
        },
        source: {
            type: String,
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: 'text',
            validator(value) {
                const type_list = [
                    'autocomplete',
                    'email',
                    'select',
                    'tel',
                    'text',
                ];

                return type_list.includes(value);
            },
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            currentComponent: 'UiInput',
            options: [],
        };
    },
    computed: {
        ...mapState(['billingInformation']),
        optionsComputed() {
            if (this.type !== 'select' && this.type !== 'autocomplete') {
                return [];
            }
            
            if (this.source) {
                return this.options;
            }

            if (!this.data) {
                return;
            }

            const data_key = this.data.replace(/[{}]/g, '');

            return get(this.billingInformation, data_key);
        },
        valueComputed: {
            set(value) {
                this.$emit('update:value', value);

                const events = this.onChange;

                if (events) {
                    this.triggerChangeEvents(events, value);
                }
            },
            get() {
                return this.value;
            },
        },
    },
    mounted() {
        this.handleComponent();
    },
    methods: {
        ...mapActions({
            updateContextValue: 'billingInformation/updateContextValue',
            updateValue: 'billingInformation/updateValue',
            deleteValue: 'billingInformation/deleteValue',
        }),
        handleComponent() {
            const components_map = {
                'select': 'UiSelect',
                'autocomplete': 'UiAutocompleteInput',
                'tel': 'UiPhoneInput',
            };

            if (this.type === 'select') {
                this.currentComponent = 'UiSelect';

                if (this.source) {
                    this.getOptions();
                }
            }

            if (components_map[this.type]) {
                this.currentComponent = components_map[this.type];
            }
        },
        replaceStringVariables(string, data = {}) {
            return Object.entries(data)
                .reduce((res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'g'), value), string);
        },
        async getOptions() {
            try {
                this.loading = true;

                if (this.source) {
                    const source_variables = this.source.match(/(?<=\{\{)([\w.-]+)(?=\}\})/g);
                    const variables_to_replace = {};
                    let options_source = this.source;

                    if (source_variables) {
                        source_variables.forEach(source_variable => {
                            variables_to_replace[source_variable] = get(this.billingInformation, source_variable)
                        });
    
                        options_source = this.replaceStringVariables(options_source, variables_to_replace);
                    }

                    const { data } = await this.$axios.get(options_source);

                    this.options = data;
                }

                if (this.value) {
                    const events = this.onChange;

                    if (events) {
                        this.triggerChangeEvents(events, this.value, false);
                    }
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async triggerChangeEvents(events, value, clear_values = true) {
            const request_events = events.filter(event => event.action === 'request');
            const context_events = events.filter(event => event.action === 'set_temporal_context');
            const value_events = events.filter(event => event.action === 'set_values');
            const clear_values_events = events.filter(event => event.action === 'clear_dependent_values');
            const response_events = {};

            for (const event of request_events) {
                const source_variables = event.url.match(/(?<=\{\{)([\w.-]+)(?=\}\})/g);
                const variables_to_replace = {};

                let formatted_source = event.url;

                if (source_variables) {
                    source_variables.forEach(source_variable => {
                        const source_prefix = source_variable.slice(0, source_variable.indexOf('.'));
                        let source_values = response_events;
    
                        if (['context', 'values'].includes(source_prefix)) {
                            source_values = this.billingInformation
                        }
                        
                        variables_to_replace[source_variable] = get(source_values, source_variable);
                    });
    
                    variables_to_replace[`values.${this.id}`] = value;
                    formatted_source = this.replaceStringVariables(event.url, variables_to_replace);
                }

                let { data } = await this.$axios.get(formatted_source);

                if (event.data_path) {
                    data = get(data, event.data_path);
                }

                response_events[event.name] = data;
            }

            for (const event of context_events) {
                Object.entries(event.values).forEach(([id, value]) => {
                    this.updateContextValue({key: id, value: get(response_events, value)})
                });
            }

            for (const event of value_events) {
                Object.entries(event.values).forEach(([id, path]) => {
                    const input_value = get(response_events, path);
                    this.updateValue({key: id, value: input_value});
                });
            }

            if (clear_values) {
                for (const event of clear_values_events) {
                    event.values.forEach(value => {
                        this.deleteValue(value);
                    });
                }
            }
        },
    },
};
</script>
