<template>
    <div class="billing-information-form-skeleton">
        <ui-list>
            <ui-list-item v-for="index in rows" :key="index" title="Country:">
                <div class="col-md-5 d-flex align-items-start justify-content-center flex-column pe-3">
                    <ui-skeleton class="w-50 mb-1" />
                    <ui-skeleton class="w-75" />
                </div>
                <div class="col-md-7">
                    <ui-skeleton class="w-100" type="input" />
                </div>
            </ui-list-item>
        </ui-list>
        <ui-skeleton class="w-25 mt-2 ms-auto" type="input" />
    </div>
</template>

<script>
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'PricingListSkeleton',
    components: {
        UiList,
        UiListItem,
        UiSkeleton,
    },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        },
    },
};
</script>
