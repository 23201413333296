<template>
    <div class="d-flex w-100 justify-content-between">
        <div>
            <img class="gateway-item__icon d-none d-md-inline-block" src="@/assets/img/payment-methods/amex.svg" alt="amex">
            <span class="m-1">
                Processing payments with Amex
            </span>
        </div>
        <template v-if="info.merchant_amex_id">
            <ui-badge variant="success" class="align-self-start badge-large">Active</ui-badge>
        </template>
        <template v-else>
            <ui-button type="submit" variant="primary" :disabled="!CAN(['write_accounts'], 'admin')" :loading="loading" class="align-self-center" @click="activateAmex">
                Activate
            </ui-button>
        </template>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';
import UiBadge from '@/components/ui/Badge';

export default {
    name: 'ActivateGatewayAmex',
    components: {
        UiButton,
        UiBadge
    },
    props: {
        info: {
            type: Object,
            default: () => { }
        },
    },

    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async activateAmex() {
            try {
                this.loading = true;
                await this.$axios.post(`/_/accounts/${this.$route.query.id}/amex/activate`)
                this.$toast.success('Your request has been processed.');
            }catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.badge-large {
    font-size: 14px;
    padding: 8px 10px;
}
</style>