<template>
    <div ref="autocomplete" v-click-outside="clickOutside" class="ui-autocomplete">
        <ui-input
            class="ui-autocomplete__input"
            type="text"
            autocomplete="off"
            v-bind="{ ...$attrs, ...$props }"
            v-model="valueComputed"
            :disabled="loading"
            @click="focusInput"
            @keydown.tab="clickOutside"
        />
        <button
            v-if="valueComputed"
            class="ui-autocomplete__clear-button"
            type="button"
            @click="clear"
        >
            <span class="fas fa-times"></span>
        </button>
        <div v-show="showList && optionsFiltered.length" class="ui-autocomplete__list-container">
            <ul ref="options" class="ui-autocomplete__list bg-white shadow rounded">
                <li
                    v-for="(option, key) in optionsFiltered"
                    class="ui-autocomplete__list-item"
                    :key="key"
                >
                    <button
                        type="button"
                        class="ui-autocomplete__list-button"
                        :class="{
                            'ui-autocomplete__list-button--selected': option === valueComputed
                        }"
                        @click="selectOption(option)"
                    >
                        {{ getOptionValue(option) }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

import UiInput from '@/components/ui/Input';

export default {
    name: 'AutocompleteInput',
    components: { UiInput },
    inheritAttrs: false,
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        model: {
            type: String,
            default: '',
            required: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        optionLabel: {
            type: String,
            default: 'label',
            required: false,
        },
        state: {
            type: Boolean,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            text: '',
            showList: false,
        };
    },
    computed: {
        valueComputed: {
            set(value) {
                this.$emit('update:value', value);
            },
            get() {
                return this.model;
            },
        },
        optionsFiltered() {
            if (!this.valueComputed) return this.options;

            return this.options.filter(option => {
                let option_value = this.getOptionValue(option);

                const regex = new RegExp(this.valueComputed, 'ig');
                const result = option_value.match(regex);
                
                return result;
            });

        },
    },
    methods: {
        clear() {
            this.$emit('update:value', '');
        },
        clickOutside() {
            this.showList = false;
        },
        focusInput() {
            this.showList = true;
        },
        getOptionValue(option) {
            let option_value = option

            if (typeof option === 'object') {
                option_value = get(option, this.optionLabel)
            }

            return option_value;
        },
        selectOption(option) {
            const option_value = this.getOptionValue(option)
            this.$emit('update:value', option_value);
            this.showList = false;
        },
    },
};
</script>

<style lang="scss">
.ui-autocomplete {
    position: relative;

    &__clear-button {
        background-color: transparent;
        border: none;
        border-radius: 100%;
        color: #3b3b3b;
        font-size: 0.8rem;
        height: 24px;
        position: absolute;
        right: 5px;
        transition: 200ms ease-in;
        top: calc(50% - 12px);
        width: 24px;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    &__list-container {
        position: absolute;
        z-index: 99;
        width: 100%;
        left: 0;
    }

    &__list {
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 250px;
    }

    &__list-button {
        display: block;
        padding: 0.5rem 1rem;
        color: black;
        background: none;
        width: 100%;
        text-align: left;
        border: none;

        &:hover, &--selected {
            background-color: #f2f7eb;
        }
    }
}
</style>
