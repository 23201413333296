<template>
    <div class="account-activitys">
        <activity-table
            :account-id="$route.query.id"
            account-section-table
            show-download-button
        />
    </div>
</template>

<script>
import ActivityTable from '@/components/activitys/ActivityTable';

export default {
    name: 'AccountActivitys',
    components: { ActivityTable },
};
</script>