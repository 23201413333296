<template>
    <div class="ui-phone-input">
        <vue-tel-input
            v-model="phone"
            :disabled="disabled"
            :dropdown-options="{
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true,
            }"
            :input-options="{
                autocomplete,
                id,
                name,
                placeholder,
                styleClasses: 'ui-phone-input__input',
            }"
            :style-classes="[
                'ui-phone-input__container',
                {
                    'ui-phone-input__container--valid': field && field.$dirty && !field.$error,
                    'ui-phone-input__container--invalid': field && field.$dirty && field.$error,
                }
            ]"
            :value="value"
            :valid-characters-only="true"
            @on-input="$emit('input', $event)"
        />
        <div v-if="field && field.$error" class="ui-input__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhoneInput',
    props: {
        autocomplete: {
            type: String,
            default: null,
            required: false
        },
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
            required: false,
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return { phone: '' };
    },
    watch: {
        phone(value) {
            this.$emit('update:value', value);
        },
        value(val) {
            if (val !== this.phone) {
                this.phone = val;
            }
        }
    },
    created() {
        if (this.value) {
            this.phone = this.value;
        }
    },
};
</script>

<style lang="scss">
.ui-phone-input {
    position: relative;

    &__container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        height: 40px;

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        padding: 4.5px 8px 4.5px 0;
        width: 100%;

        &::placeholder {
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    .vti {
        position: initial;

        &__dropdown {            
            position: initial;

            &.open, &:hover {
                background-color: transparent;
            }
        }
        
        &__dropdown-list {
            border: 1px solid $ecart-secondary-200;
            border-radius: 8px;
            max-width: 100%;
            padding: 8px;

            &.below {
                top: calc(100% + 5px)
            }
        }

        &__dropdown-item {
            padding: 4.5px 0;
        }

        &__search_box {
            border: 1px solid $ecart-secondary-200;
            border-radius: 8px;
            margin: 0;
            margin-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
        }
    }
}
</style>
