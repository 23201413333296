export default {
    methods: {
        scrollToError(other_classes = []) {
            const class_names = ['--invalid', ...other_classes];
            let invalid_inputs = [];
            
            setTimeout(() => {
                class_names.forEach(className => {
                    const invalid_input = document.querySelector(`[class$=${className}]`);
                    invalid_inputs.push(invalid_input);
                });

                const [first_invalid_input] = invalid_inputs;

                if (first_invalid_input) {
                    first_invalid_input.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 200);
        },
    },
};
