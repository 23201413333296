<template>
	<div class="address-list">
        <ui-list-group-skeleton v-if="loading" :rows="5" />
        <list-group v-else bordered shadow>
			<list-group-item class="list-group-item d-flex justify-content-between align-items-center">
                <h5 class="ui-card__title mb-0 my-1"> Address list</h5>
				<div class="address-list__actions" v-if="CAN(['write_accounts'])">
					<ui-button type="submit" variant="primary" @click="$refs.addAddressModal.show()"> 
						Add Address
					</ui-button>
				</div>
            </list-group-item>
            <list-group-item v-for="address in addresses" :key="address.name">
                <address-item :address="address" @addressDeleted="addressDeleted" @addressUpdated="addressUpdated"/>
            </list-group-item>
						<list-group-item v-if="!addresses.length">
                <empty-state title="No address found..."/>
            </list-group-item>
        </list-group>
    </div>
	<b-modal ref="addAddressModal" title="Add Address" @hidden="this.$refs.formAddress.reset()">
		<form>
			<form-address ref="formAddress"></form-address>
		</form>
		<template #footer>
			<div v-show="!loading" class="text-end">
				<ui-button :loading="saving"  variant="primary" @click="saveAddress" class="me-2"> Save Address </ui-button>
				<ui-button variant="secondary" @click="closeModal"> Close </ui-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';
import AddressItem from '@/components/accounts/AddressItem';
import ListGroup from '@/components/ui/list-group/ListGroup';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem';
import UiListGroupSkeleton from '@/components/ui/skeleton/ListGroupSkeleton';
import UiPagination from '@/components/ui/Pagination';
import UiButton from '@/components/ui/buttons/Button';
import FormAddress from '@/components/accounts/FormAddress';
import UiCard from '@/components/ui/Card';

export default {
	name: 'AddressList',
	components: {
		EmptyState,
		AddressItem, 
		ListGroup,
		ListGroupItem,
		UiListGroupSkeleton,
		UiPagination,
		UiButton,
		FormAddress,
		UiCard
	},
	data(){
		return {
			addresses: [],
			formData: {},
			loading: false,
			saving: false,
			busy: false
		}
	},
	props: {
		accountId: {
			type: String,
			default: null, 
			require: true
		}
	},
	mounted() {
		this.getAddress()
	},
	methods: {
		async getAddress() {
			try {
				this.loading = true;
				const { data } = await this.$axios.get(`/_/accounts/${this.$route.query.id}/address`);
				this.addresses = data;
			} catch(error) {
				this.$toast.error(this.getErrorMessage(error));
			} finally {
				this.loading = false;
			}
		},
		async saveAddress() {
			const formAddress = this.$refs.formAddress;

			try {
				this.saving = true;

				const payload = formAddress.formData;
				const { data } = await this.$axios.post(`/_/accounts/${this.$route.query.id}/address/create`, payload);
				this.addresses.unshift(data);
				this.$toast.success('Save address successfully.');

				this.$refs.addAddressModal.hide();
			} catch(error) {
				const status = error?.response?.status;
				const details = error?.response?.data?.status;

				if(status === 400 && details?.length){
					addressForm.setBackendErrors(details);
					return;
				}

				this.$toast.error(this.getErrorMessage(error));
			} finally {
				this.saving = false;
			}
		},
		closeModal() {
			this.$refs.addAddressModal.hide();
		},
		addressDeleted(address_name) {
            const searchIndex = this.addresses.findIndex(item => item.name === address_name);
            this.addresses.splice(searchIndex, 1);
		},
		async addressUpdated(address_name){
			try {
				this.busy = true;
				this.getAddress();
			} catch(error) {
				this.$toast.error(this.getErrorMessage(error));
			} finally {
				this.busy = false;
			}
		}
	}
}
</script>

<style lang="scss">
.address-list {
    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }
}
</style>