<template>
	<div class="row">
		<div class="mb-4"> 
			<div v-if="!isHidden('name')">
				<ui-input 
				id="name"
				name="name"
				label="Name address"
				type="text"
				v-model:value="formData.name"
				:field="v$.formData.name"
				:disabled="isDisabled('name')" />
			</div>
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('address1')">
			<ui-input
			id="address1"
			name="address1"
			label="Street"
			type="text"
			v-model:value="formData.address1"
			:field="v$.formData.address1" 
			:disabled="isDisabled('address1')"/>
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('address2')">
			<ui-input 
			id="number"
			name="number"
			label="Number"
			type="text"
			v-model:value="formData.address2"
			:field="v$.formData.address2"
			:disabled="isDisabled('address2')" />
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('country')">
			<ui-select 
			id="country"
			name="country"
			type="select"
			class="test-class w-100"
			label="Country"
			option-label="name"
			option-value="code"
			v-model:value="formData.country"
			:options="countries"
			:disabled="isDisabled('country')"
			@change="getState()" />
		</div>
	</div>
	<div class="mb-4">
		<ui-select 
		id="state"
		name="state"
		class="test-class w-100"
		label="State"
		option-label="name"
		option-value="id"
		v-model:value="formData.state"
		:options="states"
		:disabled="isDisabled('state')"
		/>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('city')">
			<ui-input 
			id="city"
			name="city"
			label="City"
			type="text"
			v-model:value="formData.city"
			:field="v$.formData.city"
			:disabled="isDisabled('city')" />
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('district')">
			<ui-input 
			id="district"
			name="district"
			label="District"
			type="text"
			v-model:value="formData.district"
			:field="v$.formData.district"
			:disabled="isDisabled('district')" />
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('postalCode')">
			<ui-input 
			id="postalCode"
			name="postalCode"
			label="Postal Code"
			type="text"
			v-model:value="formData.postalCode"
			:field="v$.formData.postalCode"
			:disabled="isDisabled('postalCode')" />
		</div>
	</div>
	<div class="mb-4">
		<div v-if="!isHidden('phone')">
			<ui-phone-input
			id="phone"
			name="phone"
			label="Phone"
			v-model:value="formData.phone"
			:field="v$.formData.phone"/>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
 
import UiInput from '@/components/ui/Input';
import UiPhoneInput from '@/components/ui/inputs/PhoneInput';
import UiSelect from '@/components/ui/Select';

import account_address_mixin from '@/mixins/account-address.mixin';

export default {
	name: 'FormAddress',
	components: { UiInput, UiPhoneInput, UiSelect },
	mixins: [account_address_mixin],
	data() {
		return {
			countries: [],
			states: [],
		}
	},
	props: {
		defaultData: {
			type: Object, 
			default: () => {},
			required: false
		}
	},
    setup: () => ({ v$: useVuelidate() }),
	async mounted() {
        this.loadDefaulData();
		this.getCountries();
    },
	methods: {
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        isHidden(inputName) {
            return this.hiddenInputs.includes(inputName);
        },
        loadDefaulData() {
            if (!this.defaultData) return;
            for(const inputName in this.formData) {
                if (this.defaultData[inputName]) {
                    this.formData[inputName] = this.defaultData[inputName];
                }
            }
        },
		async getCountries() {
			try {
				const { data } = await this.$axios.get('/_/catalogs/countries');
				this.countries = data;
				this.getState();
			} catch(error) {
				this.$toast.error(this.getErrorMessage(error));
			}
		},
		async getState() {
			const country = this.formData.country;

			if(country != ''){
				try {
					const { data } = await this.$axios.get(`/_/catalogs/states/${country}`);
					this.states = data;
				} catch(error) {
					this.$toast.error(this.getErrorMessage(error));
				}
			} else {
				return;
			}
		},
	}
}
</script>
