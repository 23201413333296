<template>
    <div v-if="eventData && eventData.length > 0">
        <perfect-scrollbar :options="scrollbarOptions" style="max-height: 550px;">
            <div v-for="(event, index) in reversedEventData" :key="index">
                <div class="row time-line" :class="{ 'no-after': index === displayLine }">
                    <div class="time-line__status-info col-3">
                        <div v-if="event.status">
                            <span  class="d-none d-sm-inline-block time-line__status-info__icon">
                                <div :class="'time-line__status-info__icon--' + event.status">
                                    <i :class="addStatusIcon(event.status) "></i>
                                </div>
                            </span>
                            <span v-if="event.status" :class="'time-line__status-info__text--'+ event.status">{{ formatText(event.status) }}</span>
                        </div>
                        <ui-badge v-else variant="secondary">
                            No information
                        </ui-badge>
                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-center">
                            <div class="d-none d-sm-inline-block">
                                <ui-avatar ref="avatar" size="xs" :account="event.admin" />
                            </div>
                            <div class="ms-2">
                                {{ event.admin.first_name }} {{ event.admin.last_name }}
                                <div class="text-sm text-black-50">{{ $dayjs(event.created_at).format('MM/DD/YYYY, LT') }}</div>
                                <div class="text-sm fw-bold text-black-50" v-if="event.event">{{ formatText(event.event) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <p class="mb-0" v-if="event.message" >
                            {{ event.message }} 
                        </p>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <div v-else class="empty-state">
        <empty-state title="No event found data..." img="time-line.svg" img-size="md"/>
    </div>
</template>

<script>
import UiBadge from '@/components/ui/Badge';
import UiAvatar from '@/components/ui/Avatar';
import EmptyState from '@/components/ui/EmptyState';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
    components: { UiAvatar, UiBadge, EmptyState, PerfectScrollbar },
    name: 'TimeLine',
    props: {
        title: {
            type: String,
            default: null,
            required: false,
        },
        eventData : {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    data() {
        return {
            scrollbarOptions: {
                suppressScrollX: true,
                wheelPropagation: true,
            },
        };
    },
    computed: {
        reversedEventData() {
            return [...this.eventData].reverse();
        },
        displayLine() {
            return this.eventData.length - 1;
        },
    },
    methods: {
        addStatusIcon(status) {
            const statusTypeMappings = {
                'accepted': 'fas fa-check-circle',
                'rejected': 'fas fa-minus-circle',
                'pending': 'fas fa-clock',
                'incomplete': 'fas fa-exclamation-circle',
                'created': 'fas fa-plus-circle',
                'in_review': 'fas fa-eye',
            };
            return statusTypeMappings[status];
        },
        formatText(text) {
            return text.charAt(0).toUpperCase() + text.slice(1).replace(/_/g, ' ');
        },
    },
};
</script>

<style lang="scss">
    .custum_button {
        border: none;
        background: none;
        cursor: pointer;
        outline: none;
        color: green;

        &:focus,
        &:hover {
            border: none;
            background: none;
            cursor: pointer;
            outline: none;
            color: green;
        }
    }
    .time-line {
        font-size: 0.95rem;
        line-height: 1.25rem;
        color: $ecart-secondary-600;
        &__status-info {
            &__icon {
                font-size: 1.3rem;
                &--rejected {
                    color: $general-error;
                }
                &--pending {
                    color: $general-pending;
                }
                &--accepted {
                    color: $general-success;
                }
                &--incomplete {
                    color: $general-black;
                }
                &--created {
                    color: $general-info;
                }
                &--in_review {
                    color: $general-warning;
                }
            }
            &__text {
                &--rejected {
                    color: $general-error;
                    margin-left: 10px;
                }
                &--pending {
                    color: $general-pending;
                    margin-left: 10px;
                }
                &--accepted {
                    color: $general-success;
                    margin-left: 10px;
                }
                &--incomplete {
                    color: $general-black;
                    margin-left: 10px;
                }
                &--created {
                    color: $general-info;
                    margin-left: 10px;
                }
                &--in_review {
                    color: $general-warning;
                    margin-left: 10px;
                }
            }
            &:after {
                content: ' ';
                background: #D4D9DF;
                display: flex;
                width: 1.5px;
                margin-left: 8px;
                height: 70px;
            }
        }
    }
    .no-after {
        .time-line__status-info {
            &:after {
                display: none;
            }
        }
    }
    .empty-state {
        text-align: center;
        &__img {
            height: auto;
            max-width: 50%;
            width: 20%;
        }

        &__title {
            color: $general-black;
            font-size: 1.12rem;
        }
    }
</style>
